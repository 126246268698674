import request from "@/utils/request";

export function workOrderCommit(data) {
  return request.post("/customer/consult/commit",data);
}

export function workOrderDetail(workNumber) {
  return request.get("/order/work/detail/" + workNumber);
}

export function anonymousUploads(data) {
  return request.post("/common/anonymous/uploads", data);
}

export function workCommit(data) {
  return request.post("/order/work/commit", data);
}